import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(private baseService: BaseService) { }

  createVersion(data: any): Observable<any> {
    return this.baseService.post('version', data);
  }
  getVersion(type?: string): Observable<any> {
    return this.baseService.get(`version/${type}`);
  }
  getSingleVersion(id: string): Observable<any>{
    console.log(id,'service');
    
    return this.baseService.get(`version/singleVersion/${id}`);
  } 
  updateVersion(id: string, data: any): Observable<any>{
    return this.baseService.put(`version/${id}`, data)
  }
  deleteVersion(data:any): Observable<any>{
    return this.baseService.delete(`version/delete`,data)
  }
} 
