import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SafeUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import { Observable, startWith, map } from 'rxjs';
import { CategoryService } from 'src/app/service/category.service';
import { ProductService } from 'src/app/service/product.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';

@Component({
  selector: 'app-add-edit-product',
  templateUrl: './add-edit-product.component.html',
  styleUrls: ['./add-edit-product.component.scss'],
})
export class AddEditProductComponent {
  addTitle = 'Add Admin';
  editTitle = 'Edit Admin';
  hide = true;
  checked = true;
  isAdd = true;
  productId!: string;
  categories: any;
  filteredOptions!: Observable<any[]> | undefined;
  categoryObject: any;
  @ViewChild('categoryInput')
  categoryInput!: ElementRef<HTMLInputElement>;
  productForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    warrentyPeriodInYears: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    categoryID: new FormControl('', [Validators.required]),
    image: new FormControl(),
  });
  batchForm = new FormGroup({
    productId: new FormControl('', [Validators.required]),
    quantity: new FormControl('', [Validators.required]),
    // batch: new FormControl(10, [Validators.required]),
  });
  preview: any = [];
  currentFile: any = [];
  edit: string = '';
  public myAngularxQrCode!: any[];
  public qrCodeDownloadLink: SafeUrl = '';
  qrvalue: any;
  spinner: boolean = false;
  selectedBatch = 10;
  isSubmitting = false;
  batchObject: any;

  constructor(
    private productService: ProductService,
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.addTitle);
    this.productId = this.route.snapshot.params['id'];
    this.edit = this.route.snapshot.queryParams['edit'];
    if (this.edit === 'false') {
      this.productForm.disable();
    }
    if (this.productId) {
      this.titleService.setTitle(this.editTitle);
      this.isAdd = false;
      this.getProductById(this.productId);
    }
    this.getAllCategories();
  }

  getProductById(id: string) {
    this.productService.getProductById(id).subscribe({
      next: (data) => {
        this.categoryObject = data.product[0].category;
        this.productForm.patchValue({
          name: data?.product[0]?.name,
          warrentyPeriodInYears: data?.product[0]?.warrentyPeriodInYears,
          description: data?.product[0]?.description,
          categoryID: data?.product[0]?.category,
        });
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not load Product',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  displayFn(category: any): string {
    return category && category.name ? category.name : '';
  }

  private _filter(name: string): any {
    const filterValue = name?.toString().toLowerCase();
    return this.categories.filter((option: { name: string }) => {
      option.name.toLowerCase().includes(filterValue);
    });
  }

  getAllCategories() {
    this.categoryService.getAllCategories().subscribe({
      next: async (categories) => {
        this.categories = await categories.categories;
        this.filteredOptions = this.productForm
          .get('categoryID')
          ?.valueChanges.pipe(
            startWith(''),
            map((category: any) => {
              // (category ? this._filter(category) : this.categories)
              const name =
                typeof category === 'string' ? category : category?.name;
              return name
                ? this._filter(name as string)
                : this.categories?.slice();
            })
          );
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not load Categories',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  batchAdd() {
    this.batchForm.patchValue({
      productId: this.productId,
    });
    this.productService.addBatchQuantity(this.batchForm.value).subscribe({
      next: (data) => {
        this.batchObject = data.batch;
        this.myAngularxQrCode = data.batch.productCodes;
        this.myAngularxQrCode = this.myAngularxQrCode.map((code) => {
          return JSON.stringify({
            productCode: code,
          });
        });
        if (data.success) {
          this.toastr.success('Batch added successfully', 'Success');
        } else {
          this.toastr.warning(
            data.error.message
              ? data.error.message
              : 'Batch could not be added',
            'Warning'
          );
        }
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not add Batch',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  getBase64Image(img: any) {
    // var canvas = document.createElement('canvas');
    // canvas.width = img.width;
    // canvas.height = img.height;
    // var ctx = canvas.getContext('2d');
    // ctx?.drawImage(img, 0, 0);
    var dataURL = img.toDataURL('image/png');
    return dataURL;
  }

  async download() {
    let batch = this.batchObject;
    this.spinner = true;

    let imgDataArr = [];
    let codesArr = [];

    for (const f of this.myAngularxQrCode) {
      this.qrvalue = JSON.stringify({
        productId: this.productId,
        productCode: f,
      });

      while (!document.querySelector('qr-code canvas')) {
        await new Promise((r) => setTimeout(r, 500));
      }

      const qrcode = document.getElementById(f);

      let imageData = this.getBase64Image(qrcode?.firstChild);

      let jsonData = JSON.parse(f);
      codesArr.push(jsonData.productCode);

      imgDataArr.push(imageData);
    }
    let name: any = this.productForm.value['name'];
    this.generatePDF(
      imgDataArr,
      codesArr,
      name,
      this.categoryObject.stickerFlag
    );
    this.spinner = false;
    // doc.save('FirstPdf.pdf');
  }

  getFormattedDate(): string {
    const date = new Date();
    const pad2 = (n: any) => (n < 10 ? '0' + n : n);
    return `${date.getFullYear()}${pad2(date.getMonth() + 1)}${pad2(
      date.getDate()
    )}${pad2(date.getHours())}${pad2(date.getMinutes())}${pad2(
      date.getSeconds()
    )}`;
  }

  generatePDF(
    imgDataArr: string[],
    codesArr: string[],
    productName: string,
    stickerFlag: string
  ) {
    if (stickerFlag == '0') {
      //  1 * 1 BARCODE STICKER 50 by 50 mm flag 0
      let doc = new jsPDF('p', 'mm', [50, 50]);
      let x = 2.5;
      let y = 2.5;
      let w = 45;
      let h = 45;
      let pageWidth = doc.internal.pageSize.getWidth();
      let index = 0;
      const lastIndex = imgDataArr.length - 1;
      for (const data of imgDataArr) {
        doc.addImage(data, 'JPEG', x, y, w, h);
        // Add product code text below the image
        const productCode = `${codesArr[index]}`;
        doc.setFontSize(10);
        const textY = y + h + 1;
        doc.text(productCode, x + w / 2, textY, { align: 'center' });
        if (index == lastIndex) {
          break;
        }
        index++;
        doc.addPage([50, 50]);
      }

      const fileName = `${productName}_${this.getFormattedDate()}`;
      doc.save(fileName);
    } else if (stickerFlag == '1') {
      let doc = new jsPDF('p', 'mm', [25, 100]);
      let x = 0;
      let y = 0;
      let w = 22;
      let h = 22;
      let pageWidth = doc.internal.pageSize.getWidth();
      let pageHeight = doc.internal.pageSize.getHeight();
      let index = 0;
      const lastIndex = imgDataArr.length - 1;

      const topMargin = 0;
      y = topMargin;

      for (const data of imgDataArr) {
        x = (pageWidth - w) / 2;

        doc.addImage(data, 'JPG', x, y, w, h);

        const productCode = `${codesArr[index]}`;
        doc.setFontSize(6);

        const textY = y + h;
        doc.text(productCode, x + w / 2, textY, { align: 'center' });

        if (index === lastIndex) {
          break;
        }

        index++;
        y = y + 25;

        if (y >= pageHeight - 10) {
          doc.addPage([25, 100]);
          y = topMargin; 
        }
      }

      const fileName = `${productName}_${this.getFormattedDate()}`;
      doc.save(fileName);
    }
  }

  productEdit() {
    const data = {
      productId: this.productId,
      name: this.productForm.value['name'],
      warrentyPeriodInYears: this.productForm.value['warrentyPeriodInYears'],
      description: this.productForm.value['description'],
      categoryID: this.productForm.value['categoryID'],
    };
    this.productService.updateProduct(data).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastr.success('Product edited successfully', 'Success');
        } else {
          this.toastr.warning(
            data.error.message
              ? data.error.message
              : 'Product could not be edited',
            'Warning'
          );
        }
        this.router.navigate(['../../product']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Product could not be edited',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onEdit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    if (this.edit === 'false') {
      this.batchAdd();
    } else {
      this.productEdit();
    }
  }

  onAdd() {
    const category: any = this.productForm.value['categoryID'];
    var formdata: any = new FormData();
    for (const file of this.currentFile) {
      formdata.append('image', file);
    }
    formdata.append(
      'warrentyPeriodInYears',
      this.productForm?.value['warrentyPeriodInYears']
    );
    formdata.append('name', this.productForm.value['name']);
    formdata.append('description', this.productForm.value['description']);
    formdata.append('categoryID', category._id);

    this.productService.createProduct(formdata).subscribe({
      next: (data) => {
        console.log(data, 'test');

        if (data.success) {
          this.toastr.success('Product added successfully', 'Success');
        } else {
          this.toastr.warning(
            data.error.message
              ? data.error.message
              : 'Product could not be added',
            'Warning'
          );
        }
        this.router.navigate(['../product']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Product could not be added',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onFileSelected(event: any) {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const files: FileList | null | any = selectedFiles;

      this.currentFile = <Array<File>>files;
      if (files) {
        this.preview = [];
        for (let index = 0; index < selectedFiles.length; index++) {
          const reader = new FileReader();

          reader.onload = (e: any) => {
            this.preview.push(e.target.result);
          };

          reader.readAsDataURL(selectedFiles[index]);
        }
      }
    }
  }

  onSubmit() {
    if (this.isAdd) {
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;
      this.onAdd();
    } else {
      this.onEdit();
    }
    setTimeout(() => {
      // Reset the flag once the operation is complete
      this.isSubmitting = false;
    }, 3000);
  }
}
