import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from 'src/app/service/auth.service';
import { environment } from 'src/environments/environment';

interface sidebarMenu {
  link: string;
  icon: string;
  menu: string;
  submenus: any[];
  expanded?: any;
}

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss'],
})
export class FullComponent {
  search: boolean = false;
  userDetails: any;
  server = environment.apiUrl;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService
  ) {
    this.userDetails = localStorage.getItem('currentUser');
    this.userDetails = JSON.parse(this.userDetails);
  }

  routerActive: string = 'activelink';

  sidebarMenu: sidebarMenu[] = [
    {
      link: '/home',
      icon: 'home_outline',
      menu: 'Dashboard',
      submenus: [],
    },
    {
      link: '/admin',
      icon: 'person_outline',
      menu: 'Admin',
      submenus: [],
    },
    {
      link: '/users',
      icon: 'groups_outline',
      menu: 'Users',
      submenus: [
        {
          link: '/leaderboard',
          icon: 'leaderboard',
          menu: 'Leaderboard',
        },
      ],
    },
    {
      link: '/category',
      icon: 'dataset_outline',
      menu: 'Category',
      submenus: [],
    },
    // {
    //   link: '/subCategory',
    //   icon: 'category_outline',
    //   menu: 'Sub Category',
    // },
    {
      link: '/product',
      icon: 'sell_outline',
      menu: 'Product',
      submenus: [],
    },
    {
      link: '/batchList',
      icon: 'qr_code_2',
      menu: 'Batch List',
      submenus: [],
    },
    {
      link: '/catalogue',
      icon: 'menu_book',
      menu: 'Catalogue',
      submenus: [],
    },
    {
      link: '/slider',
      icon: 'burst_mode',
      menu: 'Slider',
      submenus: [],
    },
    {
      link: '/outlet',
      icon: 'store',
      menu: 'Outlets',
      submenus: [],
    },
    {
      link: '/addEditCityState',
      icon: 'edit_outline',
      menu: 'City & State',
      submenus: [],
    },
    {
      link: '/versionList',
      icon: 'info',
      menu: 'App Version',
      submenus: [],
    },
    {
      link: '/settings',
      icon: 'settings',
      menu: 'Settings',
      submenus: [],
    },
  ];

  logout() {
    this.authService.doLogout();
  }

  toggleSubmenu(sidenav: any) {
    sidenav.expanded = !sidenav.expanded;
  }
}
