import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VersionService } from '../../../service/version.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-edit-version',
  templateUrl: './add-edit-version.component.html',
  styleUrls: ['./add-edit-version.component.scss']
})
export class AddEditVersionComponent implements OnInit {
  versionForm: FormGroup;
  loading: boolean = false;
  versionId: string | null = null; // To hold the ID if editing

  constructor(
    private fb: FormBuilder,
    private versionService: VersionService,
    private toastr: ToastrNotificationService,
    private router: Router,
    private route: ActivatedRoute // Inject ActivatedRoute to access route parameters
  ) {
    // Initialize the form with empty values and validators
    this.versionForm = this.fb.group({
      versionName: ['', Validators.required],
      versionCode: ['', [Validators.required, Validators.pattern('^[0-9]+$')]],
      platform: ['', Validators.required],
      isForcedUpdate: [false],
    });
  }

  ngOnInit(): void {
    // Get the version ID from the route parameters
    this.versionId = this.route.snapshot.paramMap.get('id');
    console.log(this.versionId);
    
    if (this.versionId) {
      this.getVersionDetails(this.versionId); // Fetch version details if editing
    }
  }

  getVersionDetails(id: string): void {
    this.versionService.getSingleVersion(id).subscribe({
      next: (response) => {
        console.log(response);
        
        if (response.success) {
          console.log(response);
          
          this.versionForm.patchValue({
            versionName: response.version.versionName,
            versionCode: response.version.versionCode,
            platform: response.version.platform,
            isForcedUpdate: response.version.isForcedUpdate,
          });
        } else {
          this.toastr.warning(response?.error?.message || 'Version not found', 'Warning');
          this.router.navigate(['/versionList']); 
        }
      },
      error: (error: any) => {
        this.toastr.error(error.message || 'Could not fetch version details', 'Error');
        this.router.navigate(['/versionList']); 
      },
    });
  }

  onSubmit(): void {
    if (this.versionForm.valid) {
      this.loading = true; 

      const data = this.versionForm.value; 

      if (this.versionId) {
        
        this.versionService.updateVersion(this.versionId, data).subscribe({
          next: (response) => {
            if (response.success) {
              this.toastr.success('Version updated successfully', 'Success');
              this.router.navigate(['/versionList']);
            } else {
              this.toastr.warning(response?.error?.message || 'Version could not be updated', 'Warning');
            }
          },
          error: (error) => {
            this.toastr.error(error.message || 'Version could not be updated', 'Error');
          },
          complete: () => {
            this.loading = false; 
          },
        });
      } else {
        
        this.versionService.createVersion(data).subscribe({
          next: (response) => {
            if (response.success) {
              this.toastr.success('Version added successfully', 'Success');
              this.router.navigate(['/versionList']);
              this.versionForm.reset(); 
            } else {
              this.toastr.warning(response?.error?.message || 'Version could not be added', 'Warning');
            }
          },
          error: (error) => {
            this.toastr.error(error.message || 'Version could not be added', 'Error');
          },
          complete: () => {
            this.loading = false;
          },
        });
      }
    }
  }
}
