<mat-card class="w-100">
  <mat-card-content class="padded-content">
    <div class="button-group d-flex justify-content-end mb-3">
      <div class="me-2">
        <select class="form-select" [(ngModel)]="selectedPlatform" (change)="loadVersions()">
          <option value="iOS">iOS</option>
          <option value="Android">Android</option>
        </select>
      </div>
      <button mat-raised-button color="primary" style="height: calc(2.25rem + 2px);" (click)="addVersion()">
        Add Version
      </button>
    </div>

    <div *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="error" class="error-message">
      {{ error }}
    </div>

    <table *ngIf="!loading && versions.length > 0" mat-table [dataSource]="versions" class="mat-elevation-z8">
      <!-- Table Columns -->
      <ng-container matColumnDef="versionName">
        <th mat-header-cell *matHeaderCellDef>Version Name</th>
        <td mat-cell *matCellDef="let version">{{ version.versionName }}</td>
      </ng-container>

      <ng-container matColumnDef="versionCode">
        <th mat-header-cell *matHeaderCellDef>Version Code</th>
        <td mat-cell *matCellDef="let version">{{ version.versionCode }}</td>
      </ng-container>

      <ng-container matColumnDef="platform">
        <th mat-header-cell *matHeaderCellDef>Platform</th>
        <td mat-cell *matCellDef="let version">{{ version.platform }}</td>
      </ng-container>

      <ng-container matColumnDef="isForcedUpdate">
        <th mat-header-cell *matHeaderCellDef>Forced Update</th>
        <td mat-cell *matCellDef="let version">{{ version.isForcedUpdate ? 'Yes' : 'No' }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Created At</th>
        <td mat-cell *matCellDef="let version">{{ version.createdAt | date:'short' }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="text-center">Actions</th>
        <td mat-cell *matCellDef="let version" class="text-center">
          <button mat-icon-button aria-label="Edit" (click)="editVersion(version)">
            <mat-icon color="warn">edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="Delete" (click)="deleteVersion(version._id)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Table Rows -->
      <tr mat-header-row *matHeaderRowDef="['versionName', 'versionCode', 'platform', 'isForcedUpdate', 'createdAt', 'edit']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['versionName', 'versionCode', 'platform', 'isForcedUpdate', 'createdAt', 'edit']"></tr>

      <!-- No Data Row -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="6">No versions available.</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
