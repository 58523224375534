import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { VersionService } from 'src/app/service/version.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogBoxComponent } from '../delete-dialog-box/delete-dialog-box.component';

interface Version {
  _id: string;
  versionName: string;
  versionCode: number;
  platform: string;
  isForcedUpdate: boolean;
  createdAt: string;
}

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent {
  versions: Version[] = [];
  loading = true;
  error: string | null = null;
  selectedPlatform: string = 'iOS'; // Default selection

  constructor(private versionService: VersionService, private router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.loadVersions();
  }

  addVersion() {
    this.router.navigate(['/addVersion']); 
  }
  
  deleteVersion(id: string) {
    const dialogRef = this.dialog.open(DeleteDialogBoxComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.versionService.deleteVersion(id).subscribe(
          response => {
            this.versions = this.versions.filter(version => version._id !== id);
            console.log('Version deleted:', response);
          },
          error => {
            console.error('Error deleting version:', error);
          }
        );
      } else {
        console.log('Deletion canceled');
      }
    });
  }

  editVersion(version: any) {
    this.router.navigate(['/editVersion/', version._id]); 
  }

  loadVersions(): void {
    this.loading = true; // Reset loading state
    this.versionService.getVersion(this.selectedPlatform).subscribe(
      (response) => {
        if (response.success) {
          this.versions = response.versions;
        } else {
          this.error = 'Failed to load versions';
        }
        this.loading = false;
      },
      (err) => {
        this.error = 'Failed to load versions';
        this.loading = false;
      }
    );
  }
}
