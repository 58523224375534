<form [formGroup]="versionForm" (ngSubmit)="onSubmit()" class="container mt-4">
  <div *ngIf="loading" class="loader1">
    <mat-spinner></mat-spinner>
  </div>

  <mat-card class="p-4 shadow-sm">
    <mat-card-header>
      <mat-card-title class="mat-title">Version Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="form-group mb-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Version Name</mat-label>
          <mat-icon matPrefix>label</mat-icon>
          <input matInput formControlName="versionName" />
          <mat-error *ngIf="versionForm.get('versionName')?.invalid && versionForm.get('versionName')?.touched">
            Version Name is required.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group mb-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Version Code</mat-label>
          <mat-icon matPrefix>code</mat-icon>
          <input matInput type="number" formControlName="versionCode" />
          <mat-error *ngIf="versionForm.get('versionCode')?.invalid && versionForm.get('versionCode')?.touched">
            Version Code is required and must be a number.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group mb-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Platform</mat-label>
          <mat-select formControlName="platform">
            <mat-option value="">Select a platform</mat-option>
            <mat-option value="iOS">iOS</mat-option>
            <mat-option value="Android">Android</mat-option>
          </mat-select>
          <mat-error *ngIf="versionForm.get('platform')?.invalid && versionForm.get('platform')?.touched">
            Platform is required.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group mb-3">
        <mat-checkbox formControlName="isForcedUpdate">Forced Update</mat-checkbox>
      </div>
    </mat-card-content>
    
    <mat-card-actions>
      <div class="button-container">
        <button mat-raised-button color="primary" type="submit" [disabled]="versionForm.invalid">Submit</button>
      </div>
    </mat-card-actions>
  </mat-card>
</form>
